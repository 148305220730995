/**
 * /* eslint-disable no-debugger
 *
 * @format
 */

import { message } from "antd";
import HPIDClient from "components/shared/oauth/src";
import { clientId, apiBase, apiConsoleBase } from "constants/env";

export const hpbpClient = HPIDClient({
  apiBase: apiConsoleBase,
  oAuthApiBase: apiBase,
  clientId,
  logoutApiBase: `${apiBase}/oauth/v1/logout?client_id=${clientId}&post_logout_redirect_uri=`,
  logoutRedirectUri: "/login&federated=true",
  acrValues:
    window.location.host === "console.hpbp.io"
      ? "urn:hpbp:hpid:onehp"
      : "urn:hpbp:hpid",
  logoutCallback: () => {
    localStorage.removeItem("horizon:upload-completePart");
    localStorage.removeItem("mobius:upload-completePart");
    // if (window.localStorage) {
    // window.localStorage.clear();
    // }
  },
  onError: () => {
    //  message.warning("Token expired, please login again", 5);
  },
});

export const tokenManager = hpbpClient.tokenManager({
  prefix: "horizon",
  focusLogoutTime: 43200000,
  // focusLogoutTime: 86400000 * 2,
});

window.tokenManager = tokenManager;
